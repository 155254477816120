body
{
  /*font-family: 'Noto Serif SC', serif;*/
  font-family:$font-family-serif;
  font-weight:400;
	color: #444444;
	font-size: 14px;
	padding: 0px;
}

   #logorockthunder{
    width:606px;
    height:206px;
   }
    

a
{
  color: #1d3781;
	text-decoration: underline;
}

a:hover
{
	text-decoration: none;
}
                            
.titulogordo{
  /*font-family: 'Noto Serif SC', serif;*/
  font-weight:900;
  font-size:26px;
  color:#000000;
  margin-bottom:20px; 
}

.titulogordo a{
  color:#000000;
  text-decoration:none;
}

.instagram_logo_portada{
	width:333px;
	height:101px;
}


h1 a{
  color:#000000;
  text-decoration:none;
}

h1 a:hover{
  color:#444444;
  text-decoration:underline;
}

.frasedestacada h1{
font-size:1.75rem;
}


.icono10{
	width:10px;
	height:10px;
}

.comoh1  /* para utilizar estilo de h1 sin ser h1, y guardar asi el orden */
{
  /*font-family: 'Noto Serif SC', serif;*/
  font-weight:900;
  font-size:30px;
	text-align: left;
	padding:0px;
	font-size:25px;	
	color: #1d3781;	
}

.comoh1 a{
	color: #1d3781;
}

.comoh1 a:hover{
	color: #1d3781;
}



h2{
  /*font-family: 'Noto Serif SC', serif;*/
  /*font-weight:900;
  font-size:20px;
  color:#000000;
  margin-bottom:8px;*/ 
}

h2 a{
  color:#000000;
  text-decoration:none;
}

h2 a:hover{
  color:#444444;
  text-decoration:underline;
}

.comoh2
{
  /*font-family: 'Noto Serif SC', serif;*/
  /*font-weight:900;
  font-size:20px;
	text-align: left;
	padding:0px;
  margin:0px;*/
}



h3
{
	padding: 20px 0 0;
	text-align: center;
}

h2.h2autor
{
	padding: 0px;
	margin:0px;
	font-size:10px;
	font-family:Arial,sans-serif;
	color:#1d3781;
	text-align:center;
}

h2.h2disco
{
	padding: 0px;
	margin:0px;
	font-size:10px;
	font-family:Arial,sans-serif;
	color:#000000;
	text-align:center;	
}


h4
{
	font-family: Georgia, serif;
	/*font-weight: bold;
	text-align: left;
	font-size:17px;
	color: #1d3781;
  	margin:0px;*/
	padding:0px;  
}

.comoh4
{
	font-family: Georgia, serif;
	font-weight: bold;
	text-align: left;
	font-size:17px;
	color: #1d3781;
  	margin:0px;
	padding:0px;  
}


h5
{
	/*font-size:18px;*/
	padding:0px;
  margin:0px;
}

a h2.h2autor
{
	text-decoration:none;
}

a:hover h2.h2autor 
{
	text-decoration:underline;
}


.tituloComenta
{
  font-family: 'Noto Serif SC', serif;
  font-weight:900;
	color: #000000;
  text-align: left;
	padding-top:20px;
	margin-top:20px;
	font-size:18px;

}


 .caratula_portada{
	  width:196px;
	  height:196px;
 }   



/*.container_12
{
	background-color:#FFFFFF;
}*/

.pie
{
  font-size:9px;
  border-top:2px solid #CCCCDD;
}

.contenido{
  font-family: 'Noto Serif SC', serif;
  font-weight:400;
  font-size:20px;
}


.contenido p{
  text-align:justify;
  padding:0px;
  margin:35px 0px;
}

.sigue{ /* TEXTO INFORMATIVO DE QUE UNA NOTICIA TIENE TEXTO EXTENDIDO) */
color: #5e5e6f;
font-style:italic;
font-size:15px;
cursor:pointer;
}

.sigue:hover{ /* TEXTO INFORMATIVO DE QUE UNA NOTICIA TIENE TEXTO EXTENDIDO) */
text-decoration:underline;
}

.titulogrisdiscreto{
font-weight:bold;font-size:14px;color:#555555;
}

.tablasinbordes{
width:100%;padding:0px;margin:0px;border-width:0px;
}


.contenido {
  text-align:justify;
}


.imagenBorde a{
  text-decoration:none;
}

.imagenBorde a img{
  border:2px solid #1d3781;
}

.imagenBorde a:hover img {
  border:2px solid #000000;
}

.container_12{
  margin-top:0px;
}

.contenedor_principal{ 
/*Para tapar un poco el Parallax*/
background-color:#FFFFFF;
}


/*    MENU  SUPERIOR*/
.menu
{
	font-size:16px;
  font-family: "Arial";
  font-weight:bold; 
  background:#000000;
  color:#FFFFFF;
  min-height:32px;
  vertical-align:middle;
}

.menu ul{
  display:inline;
  list-style:none;
}

.menu ul li{
  display:inline;
  list-style:none;
  margin-left:0px;
  margin-right:15px;
}

.menu a:hover{
  text-decoration:underline;
  color:#DDDDDD;
}

.menu a{
  text-decoration:none;
  color:#FFFFFF;
}

/*  FIN MENU SUPERIOR */

.tablasecciongrupos{
  border-width:3px;border-style: dotted; border-color: black;
}

.texto12{
  font-size:12px;
}

.ancho450{
  width:450px;
}

.parrafocomentarios{
  font-size:14px;
}

.textoautor{
  text-align:right;
  font-weight:300;
  font-size:14px;
  color:#777777;
}

/*  BLOQUE DE NOTICIAS*/

.unaNoticia {
  border-bottom:1px dashed #BDD3DD;
}

.etiquetasNoticia{
  font-size:12px;
}

.etiquetasNoticia a{
  color:#9b0000;
}

.datosNoticiaDestacada{
  padding-top:0px;
  margin-bottom:15px;
  display:block;     
  font-size:15px;
}

.datosNoticia{
  margin-top:0px;
  padding-top:0px;
  font-size:15px;
  clear:both;
  display:block;
}

/*.datosNoticia a{
  color:#CC0000;
}*/

.fechas{
  color:#9b0000;
}

.categoriaNoticia{
  color:#36393D;
}

/*  FIN DE BLOQUE DE NOTICIAS */

#content-wrapper{margin-top:10px;}


.cuadrado{border:1px dashed #BDD3DD;padding:0 10px;background:transparent url(/wp-content/themes/ABetterOffice/images/article_header_bg.png) repeat-x;}
.cuadrado2  {
	border: 1px dotted #a6b3b9;
  padding-left:10px;
}

#featured-articles li{margin:1px 0 0 0;padding:5px 0;}
#featured-articles li.section-header{height:30px;line-height:28px;border-bottom:1px dashed #BDD3DD;}
#featured-articles li.section-header a{float:right;line-height:32px;}




p
{
	overflow: hidden;
	padding: 5px 0;
}




.formulario {margin-left:5px;margin-top:25px;}
.formulario fieldset {border-width:0px;}
.formulario fieldset legend {color:#005095;font-weight:bold;font-size:13px;}
.formulario label {float:left;width:130px; text-align:left; margin-left: 10px;}
.formulario dd {margin-top: 10px;}
.formulario dt {margin-top: 10px;}



.rojo{
  color:#9b0000;
}


  .encabezadoTabla { /* encabezado de tablas de contenido */
    font-size:14px;
    /*background-color:#71715b;*/
    background-color:#74a7d4;
    /* Sin margen ni espaciado*/
    margin:0px;
    padding:0px;
    font-weight:bold;
    color:#FFFFFF;
  }


  .filaImpar { /* filas impares, de un color ligeramente m&aacute;s oscuro que las pares*/
    background-color:#f1f8ff;
  }




 .fechaconciertoportada{
 color:#9b0000;
 font-size:14px;
 font-weight:bold;
 }
 
 .gruposconciertoportada{
 font-family:"Arial Black",Gadget,sans-serif;
 font-size:12px;
 } 
 
 
 .encabezado{
  height:260px;
}

.encabezado1{
  height:180px;
  display:block;
}

 
 @media (min-width: 700px) {
 
 

  .frasedestacada{
    width:700px;
    margin-left:300px;
   } 

 .frasedestacada .verso{
  font-size:24px;
  color:#000000;
  margin-bottom:10px;   
 }
 
 .frasedestacada .autor{
  font-size:20px;
  margin-left:300px;
  color:#aaaaaa;   
 }

 }

 @media (max-width: 699px) { 
   #logorockthunder{
    	width:390px;
    	height: auto !important;    
   }
   
   .frasedestacada{
    margin-top:5px;
    line-height:1;    
   }
   
   .frasedestacada h1{
    font-size:20px;
   }   
   
   .frasedestacada .verso{
    font-size:20px;
    color:#000000;    
    margin-bottom:8px;
   }

   .frasedestacada .autor{
    font-size:17px;
    margin-left:270px;
    color:#aaaaaa;    
   }
   
   .page-link {
    padding: 0.275rem 0.55rem !important; 
    font-size:9px;
	}
	
    .elementomenu{
    	margin:8px 0px;
    }	
   
 	.imagen_teaser_noticia img,.imagen_completa_noticia img,.caratula_portada{
    	width:100%;
    	height: auto !important;
  	}
  	

  
   
   
   .encabezado{
    height:198px;
  }
  
  .encabezado1{
    height:120px;
    display:block;
  }
     
 
 }

 
 .frasedestacada{
    text-align:center;
  font-family: 'Noto Serif SC', serif;
  font-weight:900;
  color:#000000;  
 }
 
 
#capaLogin{
margin-bottom:10px;
}


/******************************************/
/*          FORMULARIOS                   */
/******************************************/

.formulario {
	margin-left: 5px;
	margin-top: 25px;
}
.formulario fieldset {
	border-width: 0px;
	clear: both
}
.formulario fieldset legend {
	color: #005095;
	font-weight: bold;
	font-size: 13px;
}
.formulario label {
	float: left;
	width: 130px;
	text-align: left;
	margin-left: 10px;
}

label.required{
  font-weight:bold;
}
.formulario dd {
	margin-top: 10px;
}
.formulario dt {
	margin-top: 10px;
}

.errors {
	/* Avisos de error de validacion en campos de formulario*/
	color: #FF0000;
	font-weight: bold;
	font-size: 10px;
}

.error {
	/* Avisos de error*/
	color: #FF0000;
	font-weight: bold;
}

.rojo {
	color: #FF0000;
}

.filaImpar {/* filas impares, de un color ligeramente m&aacute;s oscuro que las pares*/
	background-color: #EBEBF4;
}

.inline {
	display: inline;
	/* or */
	float: left;
	margin-right: 5px;
	margin-left: 8px;
}

.formulario .treeLabel {
	float: none;
}

.formulariojquery .description {
	font-size: 11px;
	color: #888888;
	clear: both;
}

input.error_jquery {
	color: #c3312c;
	float: left;
	font-weight: bold;
	border: #c3312c 2px solid;
}

body form label.error_jquery {
	float: left;
	color: #FFFFFF;
	background-color: #c3312c;
	border-radius: 5px;
	padding: 2px 8px;
	width: auto;
	margin-top: -2px;
	height: 24px;
	font-weight: bold;
	clear: right;
}

.ui-button-icon {/*si no el bot�n del combobox se est� viendo demasiado peque�o*/
	height: 18px;
	width: 16px;
	margin-top: -3px;
}

.envuelveelementos label {
	width: 150px;
}

.autocomplete-suggestions {
	background-color: #FFFFFF;
	border: #000000 1px solid;
}

.autocomplete-sselected {
	background-color: #E2E2E5;
	cursor: hand;
	cursor: pointer;
}

.ui-autocomplete {
	max-height: 400px;
	overflow-y: auto; /* prevent horizontal scrollbar */
	overflow-x: hidden; /* add padding to account for vertical scrollbar */
	z-index: 1000 !important;
}

/*Quizas solo para zona segura*/
body legend {
	color: #787878;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 12px;
}

body form label {
	line-height: 22px;
}

body dt {
	margin-top: 7px;
}

body .hint {
	color: #666666;
	font-size: 10px;
	clear: both;
	margin-left: 100px;
	margin-top: 2px;
}


/* Fin de quizas solo para zonasegura*/


.envuelveelementos {
	clear: both;
}

.envuelveelementos dd label {/*Para los radiobuttons del registro, no ve�a forma mejor de tocarlo*/
	margin-right: 10px;
}

/******************************************/
/*        FIN FORMULARIOS                 */
/******************************************/


.bloque_instagram_portada .columna_1_instagram{
 width:365px;float:left;padding-top:10px;
}

.bloque_instagram_portada .columna_2_instagram{
 width:555px;float:left;padding-top:10px;
}
.bloque_instagram_portada .columna_2_instagram li{
  display: inline-block; padding-left:5px;margin-left:0px;
}

.bloque_instagram_portada .columna_2_instagram li img{
 width:80px;height:80px;
}



.contenido_noticia{

}



hr{
background:#dddddd;
margin:40px 0px;
width:100%;
border:0;
height:1px;
}

.contenido iframe{
margin:0px auto;
}

#nickanonimo-label{
font-size:16px;
}

#comentario-label{
font-size:16px;
}

#fieldset-datosentrada dt{
margin-top:10px;
}
#fieldset-datosentrada dd{
margin-left:20px;
}

.guardarComentario{
padding:8px 15px;
background-color:#000000;
color:#FFFFFF;
}

.titulo_compartir_redes{
font-weight:900;font-size:20px;vertical-align:middle;color:#444446;
height:40px;
padding-top:10px;
}


.firma_foto_autor{
  margin:740px 0px 10px 20px;
  font-family:'Noto Serif SC', serif;
  color:#FFFFFF;
  font-size:12px;
  font-weight:300;
  vertical-align:bottom;
}


.texto-monoespaciado{
font-family: monospace; font-size: 12px; font-style: normal; font-variant: normal; font-weight:normal;
}



/*
<div class="video-wrapper">
  <iframe src="http://www.youtube.com/embed/AddHereVideoId?autoplay=1&amp;html5=1" frameborder="0" allowfullscreen></iframe>
</div>
*/

.video-wrapper {position: relative; padding-bottom: 56.25%; /* 16:9 */  padding-top: 25px;}
.video-wrapper iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}


.imagen_noticia_portada{
width:100%;	
}

.imagen_teaser_noticia img{
width:100%;	
}

.imagen_completa_noticia{
width:100%;	
}
