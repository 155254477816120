.rslides {
  margin: 0 auto 40px;
  }


.rslides_tabs {
  list-style: none;
  padding: 0;
  background: rgba(0,0,0,.25);
  box-shadow: 0 0 1px rgba(255,255,255,.3), inset 0 0 5px rgba(0,0,0,1.0);
  -moz-box-shadow: 0 0 1px rgba(255,255,255,.3), inset 0 0 5px rgba(0,0,0,1.0);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.3), inset 0 0 5px rgba(0,0,0,1.0);
  font-size: 18px;
  list-style: none;
  margin: 0 auto 50px;
  max-width: 540px;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  }

.rslides_tabs li {
  display: inline;
  float: none;
  margin-right: 1px;
  }

.rslides_tabs a {
  width: auto;
  line-height: 20px;
  padding: 9px 20px;
  height: auto;
  background: transparent;
  display: inline;
  }

.rslides_tabs li:first-child {
  margin-left: 0;
  }

.rslides_tabs .rslides_here a {
  background: rgba(255,255,255,.1);
  color: #fff;
  font-weight: bold;
  }



.responsive_sliders_container {
  width: 100%;
  position:relative;  
  }

.responsive_sliders {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  }

.responsive_sliders li {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  }

.responsive_sliders img {
  display: block;
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
  border: 0;
  }

.responsive_sliders_nav {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 52%;
  left: 0;
  opacity: 0.7;
  z-index: 3;
  text-indent: -9999px;
  overflow: hidden;
  text-decoration: none;
  height: 61px;
  width: 38px;
  background: transparent url("/images/estructura/responsiveslides-themes.gif") no-repeat left top;
  margin-top: -45px;
  }

.responsive_sliders_nav:active {
  opacity: 1.0;
  }

.responsive_sliders_nav.next {
  left: auto;
  background-position: right top;
  right: 0;
  }

@media screen and (max-width: 600px) {
  .responsive_sliders_nav {
    top: 47%;
    }
  }
