// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//jQuery
@import '~jquery-ui/themes/base/all.css';
@import '~jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.min.css';

// Estilos
@import '../css/estilos.css';

// Estilos
@import '../js/responsiveslides/responsiveslides.css';
@import '../js/responsiveslides/responsiveslides_tema.css';

@import '../../vendor/select2/select2/dist/css/select2.min.css';

@import '~@fortawesome/fontawesome-free/css/all.css';