// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-serif:'Noto Serif SC', serif;
$headings-font-family:'Noto Serif SC', serif;
$font-family-base:       $font-family-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700; 
$headings-font-weight: 800;

//$estilos-titulo: 'Noto Serif SC', serif;